body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black; */

  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
}

.ant-menu-light .ant-menu-item-selected {
  color: black !important;
  /* background-color: rgba(37, 39, 40, 0.8) !important; */
  background-color: rgba(37, 39, 40, 0) !important;
}

.ant-menu-item-active {
  color: red !important;
  /* background-color: rgba(37, 39, 40, 0.8) !important; */
  background-color: rgba(37, 39, 40, 0) !important;
}

.ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-active {
  color: red !important;
  /* background-color: rgba(37, 39, 40, 0.8) !important; */
  background-color: rgba(37, 39, 40, 0) !important;
}

.ant-btn-primary {
  background-color: rgba(37, 39, 40, 0.8);
  box-shadow: none;
}

.ant-breadcrumb-link {
  color: white !important;
  font-size: 20px;
  left: 180px;
  top: -50px;
  position: relative
}

/* @media (max-width: 767px) { */
  .ant-menu .ant-menu-item  { 
    /* background-color: rgba(174, 181, 176, 0.4); */
    height: 70px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.6) !important; 
    border-radius: 0px;
    width: 85%;
    left: 25px;
  }

  .ant-menu-submenu .ant-menu-submenu-title {   
    /* background-color: rgba(174, 181, 176, 0.4); */
    height: 70px !important;

    border-bottom: 1px solid rgba(255, 255, 255, 0.6) !important; 
    border-radius: 0px;
    width: 85%;
    left: 25px;
  }

  
  .ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
  {
    color: red !important;
  }

  .ant-menu-submenu .ant-menu-item {
    /* background-color: rgba(174, 181, 176, 0.2); */
    padding-left: 60px !important; 
    font-size: 20px;

    border-bottom: none !important; 
    border-radius: 0px;
    width: 85%;
    left: 25px;
  }

  .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title, .ant-menu-light>.ant-menu .ant-menu-submenu-selected {
    color: white
  }

  /* .ant-menu-submenu-inline */
  .ant-menu-inline .ant-menu-submenu-inline .ant-menu {
    max-height: 350px; /* Set a max-height according to your preference */
    overflow-y: auto; /* Adds a vertical scrollbar */
  }

  .ant-menu-submenu-arrow {
    transform: scale(2) !important;
  }

  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{
    background-color: transparent;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }

  .ant-space-item {
    width: 100%;
    display: flex;

  }
/* } */
